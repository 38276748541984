.bgcolor{
   background-color: #282c34;
   padding-left: 60px;
   padding-right: 60px;
   margin: 0;
}

.txtcontent{
    padding: 3px;
    color: white;
    margin: 0;
}