.header {
    padding: 15px;
    text-align: center;
    background: #61DAFB;
    color: white;
    font-size: 18px;
    margin: 0;
}

.header-options li{
    display:inline;
    padding-left:6px;
    padding-right:6px;
}

.header-options li.selected{
    text-decoration: underline;     
    text-decoration-color: #282c34; 
    text-decoration-style: double;  
}


.header-options li a{
    text-decoration:none;
    color: #282c34;
}

.header-options a:hover {
    font-weight: bold;
    cursor: pointer;
}